@mixin if_screen_larger_than($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  } @else if $breakpoint == sm {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $breakpoint == md {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $breakpoint == lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if $breakpoint == xl {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  }
}
