@import "variables";
@import "mixins";

.App {
  width: 100%;
  //background-color: #00f260;
}

.main-container {
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include if_screen_larger_than(xl) {
    flex-direction: row;
    max-width: 1150px;
    align-items: flex-start;
  }
}

.sticky-sidebar {
  z-index: 3;
  position: relative;
  top: 0;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  width: 90%;

  @include if_screen_larger_than(md) {
    padding-top: 136px;
  }

  @include if_screen_larger_than(xl) {
    position: sticky;
    width: 50%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-height: 890px) {
  // Example breakpoint; adjust based on testing
  .sticky-sidebar {
    padding-top: 36px; // Reduced padding for shorter screens
  }
}

.main-content {
  z-index: 3;
  width: 100%;
  @include if_screen_larger_than(xl) {
    width: 50%;
  }
}

.background-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(90deg, black, $background-mid 45%, transparent);
}

.lavalamp-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  @include if_screen_larger_than(xl) {
    width: 55%;
  }
}

.gradient-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, $background-mid 0%, transparent 100%);
  z-index: 3;
  @include if_screen_larger_than(xl) {
    width: 55%;
  }
}

.experience {
  margin-top: 76px;
  scroll-margin-top: 48px;
  @include if_screen_larger_than(xl) {
    scroll-margin-top: 148px;
  }
}

.experience-link {
  display: flex;
  flex-direction: row;
  transition: all 0.5s ease;
  border-radius: 16px;
  padding: 24px;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.experience-link:hover {
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8.4px);
  -webkit-backdrop-filter: blur(8.4px);
}

.other {
  margin-top: 24px;
  padding-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
  @include if_screen_larger_than(xl) {
    margin-top: 64px;
    scroll-margin-top: 148px;
    padding-left: 0;
    padding-right: 0;
  }
}

.about {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  @include if_screen_larger_than(xl) {
    padding-top: 136px;
  }
}

@media screen and (max-height: 890px) {
  // Example breakpoint; adjust based on testing
  .about {
    padding-top: 36px; // Reduced padding for shorter screens
  }
}

.nav-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: grey;
  margin-bottom: 24px;
  font-size: 1rem;
}

.inline-link {
  background-clip: text;
  transition: all 0.2s ease;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.inline-link:hover {
  color: transparent;
}

.underline {
  height: 1px;
  width: 0px;
  background-color: grey;
  transition: all 0.3s ease;
  margin-top: 4px;
}

li:hover a {
  font-weight: bold;
  color: white;
}

.active {
  font-weight: bold;
  color: white;
}

nav ul {
  list-style-type: none; /* Removes bullet points */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
}

.wrap {
  overflow: hidden;
  position: relative;
  height: 100%;
  // background-image: linear-gradient(-206deg, #835eff 0%, #ff008d 100%);
}

.socials {
  width: 24px;
  height: 24px;
}

.linked-in {
  margin-right: 12px;
}

.socials-svg {
  transition: all 0.5s ease;
  fill: grey;
}

.socials-svg:hover {
  fill: url(#grad1);
}

.skill-badge {
  padding: 8px;
  background: $badge-background;
  list-style-type: none;
  //border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  font-size: 0.8rem;
}

.badge-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 0;
  gap: 6px;
}

.badge-label {
  // background-image: linear-gradient(45deg, #0575e6, #00f260);
  // background-clip: text;

  color: white;
}

.theme-button {
  width: 100%;
  padding: 10px;
  border-radius: 16px;
  background: rgb(40, 40, 40);
  color: white;
  border: none;
  cursor: pointer;
}

.theme-button:disabled {
  background: rgb(30, 30, 30) !important;
  color: rgba(255, 255, 255, 0.185) !important;
}

.theme-button:hover {
  background: rgb(50, 50, 50);
}

.loader {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid rgb(22, 22, 22);
  animation: animate 1s linear infinite;
}

.loader::before {
  position: absolute;
  content: "";
  background: rgb(22, 22, 22);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 2px solid rgb(22, 22, 22);
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.theme-prompt {
  display: flex;
  padding: 10px;
  border-radius: 16px;
  background-color: rgb(30, 30, 30);
  color: white; // Ensure text is visible
  border: none; // Remove default border
  margin-bottom: 12px; // Space before the button
  margin-top: 12px;
  position: relative;
  width: 100%;
  font-size: 1rem;
  @include if_screen_larger_than(xl) {
    font-size: 0.8rem;
  }
}
input:focus {
  outline: 1px solid white; /* Removes the default focus outline */
}

.theme-changer {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: rgb(22, 22, 22);
  padding: 12px;
  @include if_screen_larger_than(xl) {
    width: 55%;
  }
}

.mini-lava-lamp {
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 100px;
  position: relative;
}

.inner-padding {
  padding-left: 24px;
  padding-right: 24px;
  @include if_screen_larger_than(xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.footer-text {
  margin-bottom: 48px;
  padding-left: 24px;
  padding-right: 24px;
  @include if_screen_larger_than(xl) {
    padding-left: 0;
    padding-right: 0;
  }
}
