@import "variables";
@import "mixins";

@font-face {
  font-family: "Barlow"; /* Give a name to your font */
  src: url("./fonts/Barlow-Regular.ttf") format("truetype"); /* Path to the font file */
  font-weight: normal; /* Specify the weight */
  font-style: normal; /* Specify the style */
}

html {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  background-color: $dark-grey-blue;
  color: rgb(207, 212, 218);
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-size: 3rem;
  margin: 0;
}

p {
  font-size: 1.2rem;
  line-height: 2rem;
}
