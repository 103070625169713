// _variables.scss
$breakpoint-xs: 320px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$dark-grey-blue: #171e22;
$bright-pink: #ff008d;
$bright-blue: #835eff;
$bright-orange: #ff8800;

$lava-bright: #fffb00f1;
$lava-dark: #1eff00d0;
$lava-background-1: #00337f;
$lava-background-2: #03afdf;

$car-1: #1a2333;
$car-2: #a22c35;
$car-3: #496393;
$car-4: #335088;

$page-background: #0d1013;

$badge-background: #171e22d2;

$background-gradient-light: #272727;
$background-gradient-dark: #0e0e0e;
$background-gradient-med: red;

$background-left: rgb(11, 12, 12);
$background-mid: rgb(22, 25, 25);
$background-mid-half-alpha: rgba(23, 26, 26, 0.2);
$background-light: rgb(44, 49, 53);
//$background-light: rgba(28, 41, 54, 1);
$background-llbase: #ff6eb4;

$lava-background-1: #000;
$lava-background-2: #333;

$link-color-1: #ff0000;
$link-color-2: #fff;
//$link-color-1: #0575e6;
//$link-color-2: #00f260;

$ll-top: rgba(6, 9, 20, 0.815);
$ll-mid: rgba(13, 20, 51, 0.418);
